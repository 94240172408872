<template>
  <div class="wrapper">
    <!-- <div class="top-charts">
            <div class="chart-one">
                <div id="pierCharts1"></div>
                <div class="textC">{{name1}}</div>
            </div>
            <div class="chart-two">
                <div id="pierCharts2"></div>
                <div class="textC">{{name2}}</div>
            </div>
        </div> -->
    <div class="progress-wrapper">
      <div class="close pointer" @click="handleProClose">
        <img src="@/assets/images/all/guanbi_icon.png" alt="" />
      </div>
      <!-- <img :class="isFoldStatus ? 'normal-img' : 'fold-img'" @click="handleFoldClick" :src="isFoldStatus ? require('@/assets/images/img/set/xianshi_icon.png') : require('@/assets/images/img/set/shouqi_icon.png')" alt="" /> -->
      <!-- <div :class="isFoldStatus ? 'fold-player' : 'play-wrapper'">
                <div class="close pointer" @click="handleProClose">
                    <img src="@/assets/images/all/guanbi_icon.png" alt="" />
                </div>
                <div class="play-btn">
                    <img src="@/assets/images/home/start.png" alt="" @click="handlePrev">
                    <i v-if="isSatrt" class="el-icon-video-play" @click="handleStartVideo"></i>
                    <i v-else class="el-icon-video-pause" @click="handlePauseVideo"></i>
                    <img src="@/assets/images/home/end.png" alt="" @click="handleNext">
                </div>
                <div class="play-bar">
                    <el-slider v-model="barValue" :show-tooltip="false" @change="handleBarChange"></el-slider>
                </div>
            </div>
            <div class="charts-wrapper">
                <div id="gantt1"></div>
                <div class="textC">桥梁施工总进度</div>
            </div> -->
      <div class="gantt-wrapper" v-show="!isFoldStatus">
        <DxGantt
          @task-dbl-click="onTaskDblClick"
          @context-menu-preparing="onContextMenuPreparing"
          :start-date-range="startDateRange"
          :hoverStateEnabled="true"
          ref="ganttRef"
          :task-list-width="930"
          height="100%"
          scale-type="weeks"
          format="yyyy-MM-dd"
          :root-value="0"
          task-tooltip-content-template="myTooltipContentTemplate"
        >
          <template #myTooltipContentTemplate="{ data: tasks }">
            <div class="custom-task-edit-tooltip">
              <div class="custom-tooltip-title">{{ tasks.project }}</div>
              <div>
                <span>计划开始时间：</span>
                {{ formatDayDate(tasks.planStartTime) }}
              </div>
              <div>
                <span>计划结束时间：</span>
                {{ formatDayDate(tasks.planEndTime) }}
              </div>
              <div>
                <span>实际开始时间：</span>
                {{ formatDayDate(tasks.actualStartTime) }}
              </div>
              <div>
                <span>实际结束时间：</span>
                {{ formatDayDate(tasks.actualEndTime) }}
              </div>
            </div>
          </template>
          <DxTasks :data-source="tasks" format="yyyy-MM-dd" key-expr="id" title-expr="project" start-expr="planStartTime" end-expr="planEndTime" progress-expr="planProgress" />
          <DxEditing :enabled="false" />
          <DxColumn :minWidth="150" data-field="project" caption="任务名称" />
          <DxColumn :width="100" data-field="status" caption="状态" />

          <DxColumn :width="120" data-field="actualStartTime" data-type="date" caption="实际开始日期" format="yyyy-MM-dd" />
          <DxColumn :width="120" data-field="actualEndTime" data-type="date" caption="实际完成日期" format="yyyy-MM-dd" />
          <DxColumn :width="100" data-field="actualTimescale" caption="实际工期" />

          <DxColumn :width="120" data-field="planStartTime" data-type="date" caption="计划开始日期" format="yyyy-MM-dd" />
          <DxColumn :width="120" data-field="planEndTime" data-type="date" caption="计划结束日期" format="yyyy-MM-dd" />
          <DxColumn :width="100" data-field="planTimescale" caption="计划工期" />
        </DxGantt>
      </div>
    </div>
  </div>
</template>

<script>
import { DxGantt, DxTasks, DxDependencies, DxResources, DxResourceAssignments, DxColumn, DxEditing, DxToolbar, DxItem, StripLine } from "devextreme-vue/gantt";
import { DxPopup, DxPosition, DxToolbarItem } from "devextreme-vue/popup";
import DxCheckBox from "devextreme-vue/check-box";
import DxNumberBox from "devextreme-vue/number-box";
import DxDateBox from "devextreme-vue/date-box";
import DxSelectBox from "devextreme-vue/select-box";
import DxTextBox from "devextreme-vue/text-box";
import { exportGantt as exportGanttToPdf } from "devextreme/pdf_exporter";
import DxButton from "devextreme-vue/button";
import notify from "devextreme/ui/notify";
import zhMessages from "devextreme/localization/messages/zh.json";
import { locale, loadMessages, formatDate, parseDate } from "devextreme/localization";
import axios from "axios";
import * as echarts from "echarts";
const ganttRef = "ganttRef";
export default {
  props: ["active"],
  data() {
    return {
      projectId: sessionStorage.getItem("projectId"),
      // gantt数据
      tasks: [],
      // 施工总进度
      totalProgress: "",
      name1: "",
      name2: "",
      // 墩号列表
      pierList: [],
      // 播放
      isSatrt: true,
      // 播放进度条
      barValue: 0,
      // 定时器
      timer: null,
      // 是否折叠
      isFoldStatus: false,
      startDateRange: new Date()
    };
  },
  mounted() {
    this.getGanttData();
    setTimeout(() => {
      this.getGanttCharts();
      this.getPierCharts();
    }, 1000);
  },
  methods: {
    // 时间转换函数
    formatTen(num) {
      return num > 9 ? num + "" : "0" + num;
    },
    formatDate(date) {
      var date = new Date(date);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hour = date.getHours();
      var minute = date.getMinutes();
      var second = date.getSeconds();
      return year + "-" + this.formatTen(month) + "-" + this.formatTen(day) + " " + this.formatTen(hour) + ":" + this.formatTen(minute) + ":" + this.formatTen(second);
    },
    formatDayDate(date) {
      if (date) {
        let time = this.formatDate(date);
        return time.split(" ")[0];
      } else {
        return "";
      }
    },
    // 获取gantt图数据
    getGanttData() {
      this.$axios.get(`${this.baseURL}base/plan/progress/${this.projectId}`).then((res) => {
        console.log("获取gantt数据", res);
        let detail = res.data.data;
        if (detail.taskList.length) {
            detail.taskList.forEach(item => {
                if (item.planEndTime) {
                    item.planEndTime = item.planEndTime.split(' ')[0] + ' 23:59:59'
                }
            })
            this.tasks = detail.taskList
        }
        // this.tasks = detail.taskList;
        if (detail.monthBefore) {
          let before = detail.monthBefore.split(" ")[0];
          let monthData = before.split("-").join(",");
          this.startDateRange = new Date(monthData);
        }

        this.pierList = detail.pierList;
        // this.$emit('setPlan', detail.taskList[0].planId)
        if (detail.pierList && detail.pierList.length) {
          this.name1 = detail.pierList[0].pierName;
          this.name2 = detail.pierList[1].pierName;
        }

        detail.schedule ? (this.totalProgress = detail.schedule) : (this.totalProgress = 0 + "%");
      });
    },
    // 施工总进度图表
    getGanttCharts() {
      let color = ["#fbc292", "#06fbfe"];
      let percentNum = this.totalProgress ? this.totalProgress : 0 + "%";
      // let percentNum = 53.16 + '%'
      let num = 0;
      if (percentNum) {
        num = percentNum.split("%")[0];
      }
      this.$chart1.dashboardRing("gantt1", color[0], color[1], num, percentNum);
    },
    // 主墩图表
    getPierCharts() {
      let color = ["#0F7BFF", "#A1CBFF"];
      let num1 = this.pierList[0].progress ? this.pierList[0].progress : 0 + "%";
      // let num1 = 88.88 + '%'
      let percentNum1 = 0;
      if (num1) {
        percentNum1 = num1.split("%")[0];
      }
      let num2 = this.pierList[1].progress ? this.pierList[1].progress : 0 + "%";
      // let num2 = 88.88 + '%'
      let percentNum2 = 0;
      if (num2) {
        percentNum2 = num2.split("%")[0];
      }
      this.$chart1.dashboardRing11("pierCharts1", color[0], color[1], num1, percentNum1);
      this.$chart1.dashboardRing11("pierCharts2", color[0], color[1], num2, percentNum2);
    },
    // 取消双击默认事件
    onTaskDblClick(e) {
      e.cancel = true;
    },
    onContextMenuPreparing(e) {
      e.cancel = true;
    },
    // 关闭按钮
    handleProClose() {
      // this.$emit("handleClosePage");
      this.$parent.isShowGantt = false;
    },
    // 折叠gantt
    handleFoldClick() {
      this.isFoldStatus = !this.isFoldStatus;
    },
    // 播放
    handleStartVideo() {
      this.isSatrt = false;
      if (this.barValue > 0) {
        this.$emit("handlePlay", "drag", this.barValue / 100);
      } else {
        this.$emit("handlePlay", "start", "");
      }
      this.timer = setInterval(() => {
        this.barValue += 10;
        if (this.barValue > 100) {
          clearInterval(this.timer);
          this.isSatrt = true;
          this.barValue = 0;
          this.$emit("handlePlay", "reset", "");
        }
      }, 1000);
    },
    // 暂停
    handlePauseVideo() {
      this.isSatrt = true;
      this.$emit("handlePlay", "end", "");
      clearInterval(this.timer);
    },
    // 拖拽播放进度条
    handleBarChange(val) {
      console.log("改变后的值", val);
      if (val > 0 && val < 100) {
        this.$emit("handlePlay", "drag", val / 100);
        this.isSatrt = false;
        this.timer = setInterval(() => {
          this.barValue += 10;
          if (this.barValue > 100) {
            clearInterval(this.timer);
            this.isSatrt = true;
            this.barValue = 0;
            this.$emit("handlePlay", "reset", "");
          }
        }, 1000);
      } else {
        this.$emit("handlePlay", "reset", "");
      }
    },
    // 后退
    handlePrev() {
      if (this.barValue > 10) {
        this.barValue -= 10;
      } else {
        this.barValue = 0;
      }
      this.$emit("handlePlay", "drag", this.barValue / 100);
    },
    // 快进
    handleNext() {
      this.barValue += 10;
      this.$emit("handlePlay", "drag", this.barValue / 100);
    }
  },
  components: {
    StripLine,
    DxGantt,
    DxTasks,
    DxDependencies,
    DxResources,
    DxResourceAssignments,
    DxColumn,
    DxEditing,
    DxToolbar,
    DxItem,
    DxCheckBox,
    DxNumberBox,
    DxDateBox,
    DxSelectBox,
    DxButton,
    DxPopup,
    DxPosition,
    DxToolbarItem,
    DxTextBox
  }
};
</script>

<style lang="scss" scoped>
::v-deep .dx-gantt .dx-gantt-taskWrapper .dx-gantt-task {
  background: linear-gradient(90deg, #1a80ec, #88dafe);
}
::v-deep .dx-scrollable-scroll-content {
  background: linear-gradient(90deg, #1a80ec, #88dafe);
}
::v-deep .dx-gantt .dx-treelist .dx-treelist-rowsview .dx-selection.dx-row > td,
.dx-gantt .dx-treelist .dx-treelist-rowsview .dx-selection.dx-row > tr > td,
.dx-gantt .dx-treelist .dx-treelist-rowsview .dx-selection.dx-row:hover > td,
.dx-gantt .dx-treelist .dx-treelist-rowsview .dx-selection.dx-row:hover > tr > td {
  background: #5e87be;
}
::v-deep .dx-gantt .dx-gantt-sel {
  background: #5e87be;
}
.custom-task-edit-tooltip {
  padding: 10px 15px;
  font-size: 13px;
  color: #fff;
  line-height: 1.8;
  background: rgba(0, 5, 36, 0.76);
  z-index: 9999 !important;
}
.textC {
  font-size: 16px !important;
}
.wrapper {
  width: 100%;
  height: 100%;
  .top-charts {
    width: 20%;
    height: 300px;
    display: flex;
    position: absolute;
    top: 15%;
    left: 40%;
    .chart-one {
      width: 50%;
      height: 100%;
      #pierCharts1 {
        width: 100%;
        height: 60%;
      }
    }
    .chart-two {
      width: 50%;
      height: 100%;
      #pierCharts2 {
        width: 100%;
        height: 60%;
      }
    }
  }
  .progress-wrapper {
    width: 100%;
    height: 300px;
    display: flex;
    position: absolute;
    // left: 25px;
    left: 130px;
    bottom: 28px;
    .close.pointer {
      position: absolute;
      top: -20px;
      right: -20px;
    }
    .fold-img {
      position: absolute;
      top: -20px;
      right: -30px;
    }
    .normal-img {
      position: absolute;
      top: 305px;
      right: -30px;
    }
    .play-wrapper {
      position: absolute;
      top: -60px;
      left: 25%;
      width: 75%;
      display: flex;
      line-height: 38px;
      background: rgba(0, 5, 36, 0.5);
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(17, 102, 255, 0.5), rgba(0, 0, 0, 0.5)) 1 1;
      border-radius: 4px;
      .play-btn {
        width: 200px;
        img {
          display: inline-block;
          margin-left: 35px;
          &:hover {
            cursor: pointer;
          }
        }
        i {
          margin-left: 35px;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .play-bar {
        flex: 1;
      }
    }
    .fold-player {
      position: absolute;
      bottom: 0;
      left: 25%;
      width: 75%;
      display: flex;
      line-height: 38px;
      background: rgba(0, 5, 36, 0.5);
      border: 1px solid;
      border-image: linear-gradient(180deg, rgba(17, 102, 255, 0.5), rgba(0, 0, 0, 0.5)) 1 1;
      border-radius: 4px;
      .play-btn {
        width: 200px;
        img {
          display: inline-block;
          margin-left: 35px;
          &:hover {
            cursor: pointer;
          }
        }
        i {
          margin-left: 35px;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .play-bar {
        flex: 1;
      }
    }
    .charts-wrapper {
      position: absolute;
      width: 20%;
      height: 100%;
      margin-left: 5%;
      #gantt1 {
        width: 100%;
        height: 80%;
        margin-top: 10%;
      }
    }
    .gantt-wrapper {
      width: 100%;
      height: 100%;
      background: #04153f;
      padding: 20px;
      border: 1px solid #2772f0;
      border-radius: 8px;
      // margin-left: 25%;
    }
  }
}
</style>
