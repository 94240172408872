<template>
  <div class="construction">
    <div class="model-wrapper" v-if="!isLoadModel">
      <div class="img-wrapper" :style="{ backgroundImage: `url(${modelImgSrc})`, backgroundSize: 'cover' }">
        <div class="load-model-wrapper" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
          <img :src="loadModelImg" alt="" @click="handleLoadModelBtnClick" />
          <span :class="isHover ? 'checked-text' : 'normal-text'">加载模型</span>
        </div>
      </div>
    </div>

    <iframe src="./static/UniversalBridge/index.html" id="unity-model" frameborder="0" ref="unityInstance" v-if="isLoadModel"> </iframe>
    <div @click="handleFwClick" :class="isShowFw ? 'fw-btn-active' : 'fw-btn'" v-if="isLoadModel">
      <el-tooltip content="复位" placement="left">
        <img :src="isShowFw ? require('@/assets/images/homeicon/fuwei-light-icon.png') : require('@/assets/images/homeicon/fuwei_grey_icon.png')" alt="" />
      </el-tooltip>
    </div>

    <div class="operation float-bg" v-if="isLoadModel">
      <ul class="img-ul">
        <!-- <li @click="handleIconImgClick(item)" v-for="(item, index) in iconList" :key="index">
          <el-tooltip :content="item.title" placement="left">
            <img :src="activeImgList.includes(item.id) ? item.activeImg : item.img" alt="" />
          </el-tooltip>
        </li> -->

        <li @click="handleWeatherClick">
          <el-tooltip content="天气实况" placement="left">
            <img :src="isShowWeather ? require('@/assets/images/homeicon/tianqii_light_icon.png') : require('@/assets/images/homeicon/tianqii_grey_icon.png')" alt="" />
          </el-tooltip>
        </li>
        <li @click="handleMonitorClick">
          <el-tooltip content="视频监控" placement="left">
            <img :src="isShowVideo ? require('@/assets/images/homeicon/jiankong-light-icon.png') : require('@/assets/images/homeicon/jiankong_grey_icon.png')" alt="" />
          </el-tooltip>
        </li>
        <li @click="handleProgressClick">
          <el-tooltip content="施工进度" placement="left">
            <img :src="isShowProgress ? require('@/assets/images/homeicon/jindu-light-icon.png') : require('@/assets/images/homeicon/jindu_grey_icon.png')" alt="" />
          </el-tooltip>
        </li>
        <li @click="handleLinearClick">
          <el-tooltip content="数据监控" placement="left">
            <img :src="isShowLinear ? require('@/assets/images/homeicon/quxianxuanzhong_icon.png') : require('@/assets/images/homeicon/quxian_grey_icon.png')" alt="" />
          </el-tooltip>
        </li>

        <li @click="handleStressClick">
          <el-tooltip content="自动化测点" placement="left">
            <img :src="isShowPoint ? require('@/assets/images/homeicon/zidong-light-icon2.png') : require('@/assets/images/homeicon/zidong_grey_icon.png')" alt="" />
          </el-tooltip>
        </li>
        <li @click="handleManMadeClick">
          <el-tooltip content="人工测点" placement="left">
            <img :src="isShowManMade ? require('@/assets/images/homeicon/rengong-light-icon.png') : require('@/assets/images/homeicon/rengong_grey_icon.png')" alt="" />
          </el-tooltip>
        </li>

        <!-- <li @click="handleStressClick">
          <el-tooltip content="应力监控" placement="left">
            <img src="@/assets/images/home/stress.png" alt="" />
          </el-tooltip>
        </li> -->
      </ul>
    </div>

    <div class="weather-wrapper com-left float-bg" v-if="isShowWeather">
      <div class="title">
        <span>气象信息</span>
        <img src="@/assets/images/home/guanbi_button.png" alt="" @click="handleCloseWeather" />
      </div>
      <div class="content">
        <div class="time-wrapper">
          <span>{{ currentDate }}</span>
          <span>{{ currentTime }}</span>
        </div>
        <div class="weather-info">
          <div class="wrapper">
            <img src="@/assets/images/home/wendu_icon.png" alt="" />
            <div class="right-info">
              <div class="info-value">
                <span class="enlarge-text">{{ temp }}</span
                >℃
              </div>
              <div class="description-text">温度</div>
            </div>
          </div>
          <div class="wrapper" style="justify-content: end">
            <img :src="weatherImg" alt="" />
            <div class="right-info">
              <div class="info-value enlarge-text">{{ weather }}</div>
              <div class="description-text">天气</div>
            </div>
          </div>
        </div>
        <div class="weather-info" style="margin-top: 35px">
          <div class="wrapper">
            <img src="@/assets/images/home/fengxiang_icon.png" alt="" />
            <div class="right-info">
              <div class="info-value enlarge-text">{{ windDir }}</div>
              <div class="description-text">风向</div>
            </div>
          </div>
          <div class="wrapper" style="justify-content: end">
            <img src="@/assets/images/home/fengji_icon.png" alt="" />
            <div class="right-info">
              <div class="info-value">
                <span class="enlarge-text">{{ windLevel }}</span
                >级
              </div>
              <div class="description-text">风级</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 视频监控 -->
    <div class="video-wrapper com-left float-bg" v-if="isShowVideo">
      <div class="title">
        <span>视频监控</span>
        <img src="@/assets/images/home/guanbi_button.png" alt="" @click="handleCloseVideo" />
      </div>
      <div v-if="!videoList.length" class="content" style="padding-top: 25%; text-align: center; min-height: 287px">
        <img style="display: inline-block" src="@/assets/images/alone/ic_jiankong_zanwu.png" alt="" />
        <div>暂无视频</div>
      </div>
      <div v-else class="content-wrapper">
        <div class="big-video video-bg" v-if="!videoList[0].sourceType || videoList[0].sourceType == 1">
          <video id="playVideo-99" class="video-js vjs-default-skin" style="width: 100%; height: 100%" muted>
            <source :src="src" type="application/x-mpegURL" />
          </video>
        </div>
        <div class="big-video video-bg" id="EZUIKitVideo-99" v-else></div>
        <div v-if="videoList.length > 2">
          <div class="small-list" v-if="!videoList[0].sourceType || videoList[0].sourceType == 1">
            <div class="small-item-w video-bg" v-for="(item, index) in videoList.slice(1)" :key="index" :class="index % 2 == 0 ? 'floatL' : 'floatR'">
              <video :id="'playVideo' + index" class="video-js vjs-default-skin" muted style="width: 100%; height: 100%">
                <source :src="item.videoUrl" type="application/x-mpegURL" />
              </video>
            </div>
          </div>
          <div class="small-list" v-else>
            <div class="small-item-w video-bg" v-for="(item, index) in videoList.slice(1)" :key="index" :class="index % 2 == 0 ? 'floatL' : 'floatR'" :id="'EZUIKitVideo' + index"></div>
          </div>
        </div>

        <div v-if="videoList.length == 2">
          <div class="small-list" v-if="!videoList[0].sourceType || videoList[0].sourceType == 1">
            <div class="small-item-w video-bg" v-for="(item, index) in videoList.slice(1)" :key="index" :class="index % 2 == 0 ? 'floatL' : 'floatR'">
              <video :id="'playVideo' + index" class="video-js vjs-default-skin" muted style="width: 100%; height: 100%">
                <source :src="item.videoUrl" type="application/x-mpegURL" />
              </video>
            </div>

            <div class="small-item-w video-bg floatR"></div>
          </div>
          <div class="small-list" v-else>
            <div class="small-item-w video-bg" v-for="(item, index) in videoList.slice(1)" :key="index" :class="index % 2 == 0 ? 'floatL' : 'floatR'" :id="'EZUIKitVideo' + index"></div>
            <div class="small-item-w video-bg floatR"></div>
          </div>
        </div>

        <div v-if="videoList.length == 1">
          <div class="small-list">
            <div class="small-item-w video-bg floatL"></div>
            <div class="small-item-w video-bg floatR"></div>
          </div>
        </div>
      </div>
      <!-- <div id="myVideoPlay" class="content">

            </div> -->
    </div>

    <!-- 施工进度 -->
    <div class="construction-wrapper com-left float-bg" v-if="isShowProgress">
      <constructionProgess :constructionData="constructionCharts" @handleClosePress="handleClosePress"></constructionProgess>
    </div>
    <div class="construction-play com-left" v-if="isShowProgress">
      <div class="flex1 box">
        <div class="play-btn">
          <!-- <img src="@/assets/images/home/start1.png" class="quick" @click="handlePrev" /> -->

          <!-- <i v-if="isSatrt" class="el-icon-video-play" @click="handleStartVideo"></i>
          <i v-else class="el-icon-video-pause" @click="handlePauseVideo"></i> -->
          <!-- <img src="@/assets/images/home/end1.png" class="quick" @click="handleNext" /> -->
          <span class="constrast">
            <el-tooltip content="进度对比" placement="top">
              <img src="@/assets/img/zhw/duibi@2x.png" @click="handleConstrastVideo" alt="" />
            </el-tooltip>
            <el-tooltip content="退出" placement="top">
              <img src="@/assets/img/zhw/stopgaolaing_icon@2x.png" @click="handleStopVideo" alt="" />
            </el-tooltip>
          </span>
          <el-tooltip :content="tips" placement="top">
            <img v-if="isSatrt" src="@/assets/images/home/play.png" class="play-pause" @click="handleStartVideo" />
            <img v-else src="@/assets/images/home/pause.png" class="play-pause" @click="handlePauseVideo" />
          </el-tooltip>
        </div>
        <div class="flex1">
          <el-slider v-model="barValue" :show-tooltip="false" @change="handleBarChange"></el-slider>
        </div>
      </div>
      <div class="right-icon right-icon-r pointer" @click="showGantt"></div>
    </div>

    <div class="info-wrapper">
      <!-- 应力监控--自动化测点 -->
      <point-info v-if="isShowStressChart" @handleClosePage="handleClosePage" :targetId="targetId" :targetName="targetName" :targetObj="targetObj"></point-info>
      <!-- 人工测点 -->
      <man-made-info v-if="isShowManMadeChart" @handleClosePage="handleClosePage" :targetId="targetIdPerson" :targetName="targetNamePerson" :targetObj="targetPersonObj"></man-made-info>
      <!-- 线形监控 -->
      <linear-info v-if="isShowLinear" @handleClosePage="handleClosePage"></linear-info>
      <!-- 施工进度 -->
      <progress-info v-if="isShowGantt" @setPlan="handleSetPlan" @handlePlay="handlePlayProgress"></progress-info>
    </div>
  </div>
</template>

<script>
import PointInfo from "./components/PointInfo";
import manMadeInfo from "./components/manMadeInfo";
import LinearInfo from "./components/LinearInfo";
import ProgressInfo from "./components/ProgressInfo";
import constructionProgess from "./components/constructionProgess";
import moment from "moment";
import myBMap from "../../utils/bmap.js";
import videojs from "video.js";
import EZUIKit from "ezuikit-js";
import { TorusGeometry } from "three/build/three.module";
export default {
  name: "construction",
  data() {
    return {
      // iconList: [
      //   {
      //     id: 1,
      //     title: "天气实况",
      //     img: require("@/assets/images/home/weather.png"),
      //     activeImg: require("@/assets/images/home/monitor.png")
      //   },
      //   {
      //     id: 2,
      //     title: "视频监控",
      //     img: require("@/assets/images/home/weather.png"),
      //     activeImg: require("@/assets/images/home/monitor.png")
      //   },
      //   {
      //     id: 3,
      //     title: "施工进度",
      //     img: require("@/assets/images/home/weather.png"),
      //     activeImg: require("@/assets/images/home/monitor.png")
      //   },
      //   {
      //     id: 4,
      //     title: "数据监控",
      //     img: require("@/assets/images/home/weather.png"),
      //     activeImg: require("@/assets/images/home/monitor.png")
      //   },
      //   {
      //     id: 5,
      //     title: "自动化测点",
      //     img: require("@/assets/images/home/weather.png"),
      //     activeImg: require("@/assets/images/home/monitor.png")
      //   },
      //   {
      //     id: 6,
      //     title: "人工测点",
      //     img: require("@/assets/images/home/weather.png"),
      //     activeImg: require("@/assets/images/home/monitor.png")
      //   }
      // ],
      // activeImgList: [],
      projectId: sessionStorage.getItem("projectId"),
      constructionCharts: { totalProgress: "", list: [] },
      isShowGantt: false,
      active: "1",
      // 是否显示应力监控的图表
      isShowStressChart: false,
      isShowManMadeChart: false,
      // 应力 targetId
      targetId: "",
      targetName: "",
      // 模型 测点key参数
      deviceKey: "YB",
      isShowWeather: true,
      isShowVideo: true,
      isShowProgress: true,
      isShowLinear: true,
      isShowPoint: false,
      isShowManMade: false,
      // 温度
      temp: "",
      // 天气
      weather: "",
      // 天气对应的图片
      weatherImg: "",
      // 风向
      windDir: "",
      // 风级
      windLevel: "",
      timer: null,
      // 当前日期 年月日
      currentDate: "",
      // 当前时间 时分秒
      currentTime: "",
      allTime: "",
      // 视频token
      videoToken: "",
      isSatrt: true,
      // 播放进度条
      barValue: 0,
      // 定时器
      timer: null,
      constrastFlag: false,
      videoList: [],
      src: "",
      targetObj: {},
      targetPersonObj: {},
      targetIdPerson: "",
      targetNamePerson: "",
      tips: "播放",
      isShowFw: false,
      isLoadModel: false, // 是否加载模型
      isHover: false,
      loadModelImg: "", // 鼠标移入移出
      modelImgSrc: "" // 模型2d图片加载 （根据外观配置模块总览背景图获取）
    };
  },
  watch: {
    isSatrt(val) {
      val ? (this.tips = "播放") : (this.tips = "暂停");
    }
  },
  mounted() {
    this.getWeatherData();
    this.tvShow();
    this.loadModelImg = require("@/assets/images/all/loadModelBtn.png");
  },
  created() {
    window.addEventListener("message", this.myFunction);
    this.getGanttData();
    this.getAppearanceData();
    this.timer = setInterval(() => {
      this.getCurrentDateTime();
    }, 1000);
  },
  beforeDestroy() {
    // this.ifrmamePostMessage({ Model_update: 'Model_update' })
    window.removeEventListener("message", this.myFunction);
    clearInterval(this.timer);
  },
  beforeRouteLeave(to, from, next) {
    // this.ifrmamePostMessage({ Model_update: 'Model_update' })
    window.removeEventListener("message", this.myFunction);
    next();
  },
  methods: {
    // 获取外观配置模块 总览背景图
    getAppearanceData() {
      this.$axios.get(`${this.baseURL}sys/sysImages/cover/${this.projectId}`).then((res) => {
        console.log("获取外观配置的总览背景图", res);
        this.modelImgSrc = `${this.baseURL}sys/sysImages/img/${res.data.data[0].id}`;
      });
      // this.$axios.get(`${this.baseURL}base/aspect/get/config?name=${sessionStorage.getItem("name")}`).then((res) => {
      //   console.log("获取外观配置的总览背景图", res);
      //   let detail = res.data.data;
      //   if (detail.pandectId) {
      //     this.modelImgSrc = `${this.baseURL}sys/sysImages/img/${detail.pandectId}`;
      //   }
      // });
    },
    // 点击加载模型按钮
    handleLoadModelBtnClick() {
      this.isLoadModel = !this.isLoadModel;
      if (this.isLoadModel) {
        // 加载模型
        window.addEventListener("message", this.postMessageFun, false); //监听gis地图
        sessionStorage.setItem('bridgeState',"Construction");
        this.unityUrl = "./static/UniversalBridge/index.html";
        this.$refs.unityInstance.contentWindow.Get_UserToken();
        this.$refs.unityInstance.contentWindow.Get_ProjectId();
        this.$refs.unityInstance.contentWindow.Get_ProjectTypeState();
        setTimeout(() => {
          this.getToken(); //传递token给gis
          window.addEventListener("message", (e) => {
            if (e.data.clickDevice !== "") {
              console.log(`模型加载完成-------------`, e, e.data.clickDevice);
              this.$bus.$emit("getTargetId", e.data.clickDevice);
            }
          });
        }, 500);
      }
    },
    // 鼠标移入
    handleMouseOver() {
      this.isHover = true;
      this.loadModelImg = require("@/assets/images/all/checkLoadModelBtn.png");
    },
    // 鼠标移出
    handleMouseLeave() {
      this.isHover = false;
      this.loadModelImg = require("@/assets/images/all/loadModelBtn.png");
    },
    // 关闭施工进度
    handleClosePress() {
      this.isShowGantt = false;
      this.isShowProgress = false;
    },
    // 获取gantt图数据
    getGanttData() {
      // this.constructionCharts = {};
      this.$axios.get(`${this.baseURL}base/plan/progress/${this.projectId}`).then((res) => {
        console.log("获取gantt数据", res);
        let detail = res.data.data;
        this.tasks = detail.taskList;
        this.pierList = detail.pierList;
        // this.$emit('setPlan', detail.taskList[0].planId)
        if (detail.pierList && detail.pierList.length) {
          this.name1 = detail.pierList[0].pierName;
          this.name2 = detail.pierList[1].pierName;
        }

        detail.schedule ? (this.totalProgress = detail.schedule) : (this.totalProgress = 0 + "%");
        this.constructionCharts.totalProgress = this.totalProgress;
        // this.constructionCharts["list"] = detail.pierList || [];
        // this.constructionCharts.list = detail.pierList;

        console.log("detail.pierList", detail.pierList);
        this.constructionCharts.list = detail.pierList;

        // this.getGanttCharts()
      });
    },
    // 施工总进度图表
    getGanttCharts() {
      let color = ["#fbc292", "#06fbfe"];
      let percentNum = this.totalProgress ? this.totalProgress : 0 + "%";
      // let percentNum = 53.16 + '%'
      let num = 0;
      if (percentNum) {
        num = percentNum.split("%")[0];
      }
      this.$chart1.dashboardRing("gantt1", color[0], color[1], num, percentNum);
    },

    showGantt() {
      // this.isShowStressChart = false;
      // this.isShowManMadeChart=false
      this.isShowLinear = false;
      this.isShowGantt = !this.isShowGantt;
    },
    // unity 获取token
    getToken() {
      this.$refs.unityInstance.contentWindow.Get_UserToken();
    },
    // set plan 进度计划id
    setPlan(id) {
      this.$refs.unityInstance.contentWindow.Set_PlanId(id);
    },
    // 显示模型上的应力测点
    getPointDisplay(data) {
      // this.$refs.unityInstance.contentWindow.Set_DeviceState_ShowMode(data);
      this.$refs.unityInstance.contentWindow.Set_ProgressPlan_DeviceTypeMode(1);
    },
    // 人工测点
    getManMadeDisplay(data) {
      // this.$refs.unityInstance.contentWindow.Set_DeviceState_ShowMode(data);
      this.$refs.unityInstance.contentWindow.Set_ProgressPlan_DeviceTypeMode(0);
    },
    // 播放视频
    startPlay() {
      this.resetPlay();
      this.$refs.unityInstance.contentWindow.Set_ProgressPlan_Play();
    },
    // 暂停视频
    endPlay() {
      this.$refs.unityInstance.contentWindow.Set_ProgressPlan_Pause();
    },
    // 拖拽进度
    dragPlay(time) {
      this.$refs.unityInstance.contentWindow.Set_ProgressPlan_Jump(time);
    },
    // 重置
    resetPlay() {
      this.$refs.unityInstance.contentWindow.Set_ProgressPlan_Reset();
      this.contrastRealMode();
    },
    // 对比播放
    contrastPlay(flag) {
      // this.$refs.unityInstance.contentWindow.Set_ProgressPlan_ComparedState(flag);
      if (flag) {
        this.$refs.unityInstance.contentWindow.Set_ProgressPlan_ComparedMode();
      } else {
        this.contrastRealMode();
      }
    },
    // 施工预览状态
    contrastRealMode() {
      // this.$refs.unityInstance.contentWindow.Set_ProgressPlan_ComparedState(flag);

      this.$refs.unityInstance.contentWindow.Set_ProgressPlan_RealMode();
    },

    // unity 模型
    myFunction(e) {
      console.log("获取unity模型", e, "this.isShowPoint", this.isShowPoint, "this.isShowManMade", this.isShowManMade);
      if (!e.data.clickDevice) {
        return;
      }
      if (e.data.clickDevice) {
        console.log("unity返回设备编号-------", JSON.parse(e.data.clickDevice).id, this.targetObj);

        if (this.isShowPoint) {
          this.isShowStressChart = true;
          this.$nextTick(() => {
            this.targetId = JSON.parse(e.data.clickDevice).id;
            this.targetName = JSON.parse(e.data.clickDevice).DevNum || JSON.parse(e.data.clickDevice).code;
            this.targetObj = JSON.parse(e.data.clickDevice);
          });
        }
        if (this.isShowManMade) {
          this.isShowManMadeChart = true;
          this.$nextTick(() => {
            this.targetIdPerson = JSON.parse(e.data.clickDevice).id;
            this.targetNamePerson = JSON.parse(e.data.clickDevice).DevNum || JSON.parse(e.data.clickDevice).code;
            this.targetPersonObj = JSON.parse(e.data.clickDevice);
          });
        }
      }
      this.getToken();
    },
    // 日期
    getCurrentDateTime() {
      this.currentDate = moment(new Date()).format("YYYY" + "年" + "MM" + "月" + "DD" + "日");
      this.currentTime = moment(new Date()).format("HH:mm:ss");
    },
    // 获取天气
    getLocationWeather(id) {
      this.$axios.get(`https://api.qweather.com/v7/weather/7d?location=${id}&key=582fb00f827e460a808eca64a938bd77`).then((res) => {
        console.log("天气情况-----------------------", res);
        let detail = res.data.daily[0];
        this.temp = detail.tempMax; // 温度
        this.weather = detail.textDay; // 天气
        this.windDir = detail.windDirDay; // 风向
        this.windLevel = detail.windScaleDay; // 风级
        let img = detail.iconDay;
        this.weatherImg = require(`../../assets/images/weather/${img}.png`);
      });
    },
    // 获取城市对应的code代码
    getCityCode(city) {
      this.$axios.get("./weather.json").then((res) => {
        console.log("存储的城市代码-----------------------", res);
        res.data.forEach((item) => {
          if (city.indexOf(item.name) != -1) {
            this.getLocationWeather(item.id);
          }
        });
      });
    },
    // 获取天气预报数据
    getWeatherData() {
      myBMap.init().then((BMap) => {
        const geolocation = new BMap.Geolocation();
        geolocation.getCurrentPosition(
          (position) => {
            console.log("position--------------------------------------", position);
            let city = position.address.city;
            this.getCityCode(city);
          },
          (e) => {
            console.log(e);
            console.log("定位失败");
          },
          { provider: "baidu" }
        );
      });

      return;
    },
    handleFwClick() {
      console.log("aaaaaaa");
      // 根据产产品需求，点击选中，1秒之后改变为不选中状态
      this.isShowFw = true;
      this.$refs.unityInstance.contentWindow.Set_ResetCamera();
      setTimeout(() => {
        this.isShowFw = false;
      }, 1000);
    },
    // 天气实况
    handleWeatherClick() {
      // this.active = 1
      // this.isShowStressChart = false
      this.isShowWeather = !this.isShowWeather;
    },
    // 关闭天气
    handleCloseWeather() {
      this.isShowWeather = false;
    },
    // 关闭视频
    handleCloseVideo() {
      this.closeVideo();

      this.isShowVideo = false;
    },

    // 监视器是否显示
    tvShow() {
      console.log("视频切换", this.isShowVideo);
      // if(this.win==1)this.win=0;

      // "bid=10008&videoName=null" "http://114.116.93.53:8197/pandect/select/bridge/video"

      // "videoName=11" "http://114.116.93.53:8197/pandect/select/video"     总览视频

      // setTimeout(()=>{
      if (this.isShowVideo) {
        // if (this.all == "1") {
        //   var path = `pandect/select/video`;
        //   console.log("总览的视频");
        // } else {
        //   console.log("单桥的视频");
        //   var path = `pandect/select/bridge/video?bid=${this.projectId}&videoName=`;
        // }
        // this.projectId = "10008";
        let path = `buildpandect/video/${this.projectId}`;

        console.log("打开");
        this.videoData(path); //获取视频数据

        // this.getVideo(); //获取萤石云视频
      } else {
        this.closeVideo();
      }
      // this.all = 1;
      // }, 200)
    },
    closeVideo() {
      console.log("关闭视频窗口");
      if (this.videoList.length == 0) {
        return;
      }

      console.log("关闭", this.videoList[0].sourceType);

      if (!this.videoList[0].sourceType || this.videoList[0].sourceType == 1) {
        var player = videojs("playVideo-99");
        player.dispose();
        this.videoList.forEach((item, index) => {
          let player = videojs("playVideo" + index);
          player.dispose(); //dispose销毁
        });
      }
    },
    // 视频请求     whole/allview
    videoData(path) {
      // if(this.all = 0){
      //     var name = '培森';
      // }else{
      //     var name = ''
      // }
      // pandect/select/video?videoName=${this.name}
      this.$axios
        .get(`${this.baseURL}${path}`)
        .then((res) => {
          console.log("获取视频数据", res.data.data);
          //  this.videoList = res.data.data;
          // var data = res.data.data;
          // var arr = [];
          // data.forEach((item, index) => {
          //   var num = item.list;
          //   num.forEach((item1, index1) => {
          //     arr.push(item1);
          //   });
          // });

          this.videoList = res.data.data || [];
          // this.videoList[0].videoUrl = "https://d2zihajmogu5jn.cloudfront.net/bipbop-advanced/bipbop_16x9_variant.m3u8";
          // this.videoList[1].videoUrl = "https://d2zihajmogu5jn.cloudfront.net/bipbop-advanced/bipbop_16x9_variant.m3u8";
          // this.videoList[2].videoUrl = "https://d2zihajmogu5jn.cloudfront.net/bipbop-advanced/bipbop_16x9_variant.m3u8";

          if (this.videoList.length) {
            if (!this.videoList[0].sourceType || this.videoList[0].sourceType == 1) {
              this.src = this.videoList[0].videoUrl;
              this.$nextTick(() => {
                this.getVideo("-99");
              });
            } else {
              this.$nextTick(() => {
                this.getEZUIKit(this.videoList[0], "-99");
              });
            }
            this.videoList.slice(1).forEach((item, index) => {
              this.$nextTick(() => {
                if (!item.sourceType || item.sourceType == 1) {
                  this.getVideo(index);
                } else {
                  this.getEZUIKit(item, index);
                }
              });
            });
          }

          // console.log("this.videoList", this.videoList);

          // this.src = this.videoList[0].videoUrl; //播放主视频
          // this.$nextTick(() => {
          //   var player = videojs("playVideoList");
          //   player.play();
          // });
          // this.videoList.forEach((item, index) => {
          //   //遍历播放播放多个视频
          //   this.$nextTick(() => {
          //     let player = videojs("playVideoList" + index);
          //     player.play();
          //   });
          // });

          // this.options = data; //视频搜索下拉框
        })
        .catch((err) => {});
    },
    getVideo(index) {
      let player = videojs("playVideo" + index);
      player.play();
    },
    // // 萤石云视频
    getEZUIKit(item, index) {
      if (!this.videoToken) {
        var data = `appKey=${item.candAppkey}&appSecret=${item.candSecret}`;

        // var data = "appKey=a2f0529eddfc4726bc46ab78b9b15609&appSecret=46f68bd6322b7ea2ac11b7843715c389";
        this.$axios
          .post(`https://open.ys7.com/api/lapp/token/get?${data}`)
          .then((res) => {
            console.log("获取token", res.data.data);
            this.videoToken = res.data.data.accessToken;
            this.playEZUIKit(index, res.data.data.accessToken);
          })
          .catch((err) => {});
      } else {
        this.playEZUIKit(index, this.videoToken);
      }
    },
    playEZUIKit(index, token) {
      let arr = [{ src: "ezopen://open.ys7.com/G14450541/1.live" }];

      var player1 = new EZUIKit.EZUIKitPlayer({
        id: "EZUIKitVideo" + index, // 视频容器ID
        accessToken: token,
        url: arr[0].src,
        width: "100%"
      });
      player1.play();
    },
    // // 获取视频token
    // tokenData() {

    // },
    // 应力监控
    handleStressClick() {
      this.active = 2;
      this.isShowPoint = !this.isShowPoint;
      this.isShowManMade = false;
      this.resetPlay();
      if (this.isShowPoint) {
        this.getPointDisplay(this.deviceKey + "_" + 1); // 1开启
      } else {
        // this.getPointDisplay(this.deviceKey + "_" + 0); // 0关闭
        this.resetPlay();
      }
    },
    // 人工测点
    handleManMadeClick() {
      // this.isShowManMadeChart = true;
      this.active = 2;
      this.isShowManMade = !this.isShowManMade;
      this.resetPlay();
      this.isShowPoint = false;
      if (this.isShowManMade) {
        this.getManMadeDisplay(this.deviceKey + "_" + 1); // 1开启
      } else {
        // this.getManMadeDisplay(this.deviceKey + "_" + 0); // 0关闭
        this.resetPlay();
      }
    },
    // 线形监控
    handleLinearClick() {
      this.active = 3;
      // this.isShowStressChart = false;
      // this.isShowProgress = false;
      this.isShowGantt = false;
      // this.isShowVideo = false;
      this.isShowLinear = !this.isShowLinear;
    },
    // 视频监控
    handleMonitorClick() {
      this.active = 4;
      // this.isShowStressChart = false;
      this.isShowLinear = false;
      this.isShowGantt = false;
      this.isShowVideo = !this.isShowVideo;
      // if (this.isShowVideo) {
      //   this.getVideo();
      // }
      this.tvShow();
    },
    // 施工进度
    handleProgressClick() {
      this.active = 5;
      // this.isShowLinear = false;
      this.isShowGantt = false;
      // this.isShowStressChart = false;
      // this.isShowVideo = false;
      this.isShowProgress = !this.isShowProgress;
    },
    // 关闭
    handleClosePage(flag) {
      // this.isShowProgress = false;
      // this.isShowStressChart = false;
      // this.isShowManMadeChart = false;
      // this.isShowLinear = false;
      // // this.isShowVideo = false;
      // this.isShowGantt = false;
      this[flag] = false;
      this.active = "";
    },
    // 播放、暂停、拖动
    handlePlayProgress(type, val) {
      console.log("播放类型--------", type);
      switch (type) {
        case "start":
          this.isShowManMade = false;
          this.isShowPoint = false;
          // 播放
          this.startPlay();
          break;
        case "end":
          // 暂停
          this.endPlay();
          break;
        case "drag":
          // 拖拽
          this.endPlay();
          this.dragPlay(String(val));
          break;
        case "reset":
          // 重置
          this.resetPlay();
          break;
      }
    },
    // set plan
    handleSetPlan(val) {
      this.setPlan(String(val));
    },
    // 播放
    handleStartVideo() {
      this.isSatrt = false;
      if (this.barValue > 0) {
        // this.$emit("handlePlay", "drag", this.barValue / 100);
        this.handlePlayProgress("drag", this.barValue / 100);
      } else {
        // this.$emit("handlePlay", "start", "");
        this.handlePlayProgress("start", "");
      }
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        this.barValue += 10;
        if (this.barValue > 100) {
          clearInterval(this.timer);
          this.isSatrt = true;
          this.barValue = 0;
          // this.$emit("handlePlay", "reset", "");
          // this.handlePlayProgress("reset", "");
        }
      }, 1000);
    },
    // 暂停
    handlePauseVideo() {
      this.isSatrt = true;
      // this.$emit("handlePlay", "end", "");
      this.handlePlayProgress("end", "");

      clearInterval(this.timer);
    },
    // 拖拽播放进度条
    handleBarChange(val) {
      console.log("改变后的值", val);
      if (val > 0 && val < 100) {
        // this.$emit("handlePlay", "drag", val / 100);
        this.handlePlayProgress("drag", val / 100);

        this.isSatrt = false;
        this.timer = setInterval(() => {
          this.barValue += 10;
          if (this.barValue > 100) {
            clearInterval(this.timer);
            this.isSatrt = true;
            this.barValue = 0;
            // this.$emit("handlePlay", "reset", "");
            this.handlePlayProgress("reset", "");
          }
        }, 1000);
      } else {
        // this.$emit("handlePlay", "reset", "");
        this.handlePlayProgress("reset", "");
      }
    },
    // 停止
    handleStopVideo() {
      clearInterval(this.timer);
      this.isSatrt = true;
      this.barValue = 0;
      // this.$emit("handlePlay", "reset", "");
      this.handlePlayProgress("reset", "");
    },
    // 对比
    handleConstrastVideo() {
      this.constrastFlag = !this.constrastFlag;
      this.contrastPlay(this.constrastFlag);
    },
    // 后退
    handlePrev() {
      if (this.barValue > 10) {
        this.barValue -= 10;
      } else {
        this.barValue = 0;
      }
      this.$emit("handlePlay", "drag", this.barValue / 100);
    },
    // 快进
    handleNext() {
      this.barValue += 10;
      this.$emit("handlePlay", "drag", this.barValue / 100);
    }
  },
  components: {
    PointInfo,
    LinearInfo,
    ProgressInfo,
    constructionProgess,
    manMadeInfo
  }
};
</script>

<style scoped lang="scss">
.construction {
  width: 100%;
  height: 100%;
  .fw-btn {
    position: absolute;
    z-index: 3;
    right: 49px;
    top: 10%;
  }
  .fw-btn-active {
    position: absolute;
    z-index: 3;
    right: 46px;
    top: calc(10% - 3px);
  }
  #unity-model {
    width: 100%;
    height: 100%;
  }
  .operation {
    position: absolute;
    top: 15%;
    right: 35px;
    .img-ul {
      border: 1px solid #2667db;
      border-radius: 33px 33px 33px 33px;
      padding: 10px 10px 0 10px;
      background-image: radial-gradient(circle at 50% 53%, #01042c 0%, #0b2d5a 83%);
      li {
        margin-bottom: 15px;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          cursor: pointer;
        }
        // img {
        //   width: 30px;
        //   height: 30px;
        // }
      }
    }
  }

  .float-bg {
    // border: 1px solid #1a80ec;
    // border-image: linear-gradient(#1a80ec, #88dafe) 30 30;
    // border-radius: 8px;
    // background: #000;
    // background-image: radial-gradient(circle at 50% 53%, #01042c 0%, #0b2d5a 83%);
    // clip-path: inset(0 round 8px);
  }

  .weather-wrapper {
    position: absolute;
    top: 10%;
    left: 50px;
    width: 320px;
    background: radial-gradient(#01042c, #0b2d5a);
    border-radius: 8px;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 15px;
      height: 38px;
      background: linear-gradient(90deg, #00398d, #051430 99%);
      border-radius: 8px 8px 0 0;
      img {
        width: 12px;
        height: 12px;
        display: inline-block;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .content {
      padding: 20px 15px;
      .time-wrapper {
        display: flex;
        justify-content: space-between;
      }
      .weather-info {
        display: flex;
        margin-top: 20px;
        .wrapper {
          width: 50%;
          display: flex;
          align-items: center;
          img {
            width: 44px;
            height: 44px;
          }
          .right-info {
            margin-left: 15px;
            color: #cbe1fc;
            width: 60px;
            .description-text {
              font-size: 12px;
              margin-top: 5px;
            }
            .enlarge-text {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .video-wrapper,
  .construction-wrapper {
    position: absolute;
    top: calc(9% + 267px);
    right: 130px;
    width: 520px;
    background: radial-gradient(#01042c, #0b2d5a);
    border-radius: 8px;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;
      background: linear-gradient(90deg, #00398d, #051430 99%);
      border-radius: 8px 8px 0 0;
      img:hover {
        cursor: pointer;
      }
    }
    .content {
      min-height: 300px;
    }
    .content-wrapper {
      box-sizing: border-box;
      padding: 16px 14px;
      height: 287px;

      overflow-y: auto;
      .big-video {
        height: 164px;
      }
      .small-list {
        // margin-top: 10px;
        overflow: hidden;
        .small-item-w {
          width: 140px;
          height: 79px;
          margin-top: 10px;
        }
      }
      .video-bg {
        background: #03255a url("../../assets/img/zhw/shipin_kongzhuangtai.png") center center no-repeat;
      }
    }
  }
  .construction-wrapper {
    top: calc(8% + 614px);
    // .construction-content-wrapper {
    //   box-sizing: border-box;
    //   padding: 16px 14px;
    //   .construction-chart {
    //     height: 89px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //   }
    //   .total-text {
    //     font-family: SourceHanSansCN-Medium;
    //     font-weight: 500;
    //     font-size: 16px;
    //     color: #ffffff;
    //     letter-spacing: 0;
    //     text-align: center;
    //   }
    // }
  }

  .construction-play {
    position: absolute;
    height: 50px;
    right: 130px;
    width: 520px;
    top: calc(8% + 819px);
    border: 1px solid #0b2d5a;
    background-color: rgba(0, 5, 36, 0.5);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right-icon {
      width: 22px;
      height: 22px;
      margin-right: 7px;
      margin-left: 49px;
      background-image: url(../../assets/img/zhw/push_button@2x.png);
      background-size: cover;
    }
    .right-icon-r {
      transform: rotate(0deg);
    }
    .right-icon-l {
      transform: rotate(-180deg);
    }

    .play-btn {
      // width: 73px;
      margin-right: 15px;
      margin-left: 12px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .constrast {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 73px;
        padding: 5px;
        box-sizing: border-box;
        background: url("../../assets/img/zhw/dibu@2x.png") no-repeat;
        background-size: 100% 100%;
        height: 30px;
        margin-right: 16px;
        img {
          width: 12px;
          height: 12px;
        }
      }
      .quick {
        width: 10px;
        height: 10px;
      }
      .play-pause {
        width: 30px;
        height: 30px;
      }
      img {
        display: inline-block;
        // margin-right: 10px;
        &:hover {
          cursor: pointer;
        }
      }
      i {
        // margin-right: 10px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .floatL {
    float: left;
  }
  .floatR {
    float: right;
  }
  .com-left {
    left: 31px;
    width: 320px;
  }
  .info-wrapper {
    width: calc(100% - 552px);
    margin: 0 auto;
    position: relative;
    // width: calc(100% - 50px);
    /* height: 400px;
            position: absolute;
            left: 25px;
            bottom: 70px; */
    /* padding: 0 25px 35px 25px; */
    /* background: #04153f; */
  }
}

.model-wrapper {
  flex: 1;
  height: 100%;
  div.img-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    .load-model-wrapper {
      position: absolute;
      right: 30px;
      top: 150px;
      width: 56px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      span {
        font-family: SourceHanSansCN-Medium;
        font-size: 14px;
        font-weight: 500;
        margin-top: 15px;
        letter-spacing: 0;
      }
      span.checked-text {
        color: #d4deff;
      }
      span.normal-text {
        color: #748ace;
      }
    }
  }
  /* height: calc(100% - 10px); */
}
</style>
