<template>
  <div class="linear-wrapper" :class="true ? 'line-w900' : 'line-w400'">
    <div class="close pointer" @click="handleLineClose">
      <img src="../../../assets/images/all/guanbi_icon.png" alt="" />
    </div>
    <div class="box linear-content-wraper">
      <ul class="type-ul">
        <li v-for="item in typeList" :key="item.id" @click="handleTypeClick(item)" :class="item.id == typeActive ? 'active-color' : ''">
          {{ item.name }}
        </li>
      </ul>
      <div class="flex1">
        <!-- 线形监控 -->
        <div v-if="typeActive == 1" class="linear-content">
          <ul class="linear-ul">
            <!-- <li class="title">{{ branchName }}高程曲线图</li> -->
            <li v-for="item in branchList" :key="item.id" @click="handleLinearClick(item)" :class="item.id == branchActive ? 'active-color' : ''">
              {{ item.name }}
            </li>
          </ul>
          <!-- <div class="title">{{ branchName }}高程曲线图</div> -->
          <div class="linear-content-bg">
            <div id="linearCharts"></div>
            <div style="height: 83px" v-if="imgData.img">
              <measure-img :imgData="imgData" :img="imgData.img" />
            </div>
          </div>
        </div>
        <!-- 应力监控 -->
        <div v-else class="stress-content">
          <ul class="linear-ul">
            <li></li>

            <!-- <li class="title">{{ branchName }}高程曲线图</li> -->
            <!-- <li v-for="item in branchList" :key="item.id" @click="handleLinearClick(item)" :class="item.id == branchActive ? 'active-color' : ''">
              {{ item.name }}
            </li> -->
          </ul>
          <div class="define-legend">
            <el-divider class="my-divider"></el-divider>
            <span style="margin: 0 20px 0 10px">实测值</span>
            <span class="dotted-line">---</span>
            <span style="margin-left: 10px">理论值</span>
          </div>
          <div class="linear-content-bg">
            <div id="stressCharts"></div>
            <div style="height: 83px">
              <measure-img :imgData="imgData1" :img="imgData1.img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import measureImg from "@/components/measureImg";

export default {
  components: {
    measureImg
  },
  data() {
    return {
      imgData: { img: "", list: [] },
      imgData1: { img: "", list: [] },
      projectId: sessionStorage.getItem("projectId"),
      // 类型 线形监控/应力监控 默认选中
      typeActive: 1,
      // 类型
      typeList: [
        {
          id: 1,
          name: "线形监控"
        },
        {
          id: 2,
          name: "应力监控"
        }
      ],
      // 线形监控 分部工程
      branchList: [],
      // 线形监控 默认选中
      branchActive: "",
      branchName: ""
    };
  },
  created() {
    this.getAllBranch();
  },
  methods: {
    // 获取全部分部工程
    getAllBranch() {
      this.$axios.get(`${this.baseURL}section/branch/${this.projectId}`).then((res) => {
        console.log("获取全部分部工程数据", res);
        this.branchList = res.data.data;
        this.branchActive = res.data.data[0].id;
        this.branchName = res.data.data[0].name;
        // 画图
        this.getLinearCharts(res.data.data[0].id);
      });
    },
    // 根据id获取图片流

    getImg(data) {
      if (this.typeActive == 1) {
        this.imgData["img"] = "";
        this.imgData["list"] = [];
        if (data.imageId) {
          this.imgData["img"] = `${this.baseURL}sys/sysImages/img/${data.imageId}`;
          let reg = {
            back: data.back,
            backName: data.backName,
            front: data.front,
            frontName: data.frontName
          };
          this.imgData["list"].push(reg);
        }
        console.log("传参数据", this.imgData);
      } else {
        this.imgData1["img"] = "";
        this.imgData1["list"] = [];
        if (data.imageId) {
          this.imgData1["img"] = `${this.baseURL}sys/sysImages/img/${data.imageId}`;
          // let reg = {
          //   back: data.back,
          //   backName: data.backName,
          //   front: data.front,
          //   frontName: data.frontName
          // };

          this.imgData1["list"] = data.list || [];
        }
        console.log("传参数据", this.imgData1);
      }
    },

    // 线形监控 获取图表数据
    getLinearCharts(branchId) {
      this.$axios.get(`${this.baseURL}buildpandect/line/${this.projectId}/${branchId}`).then((res) => {
        console.log("获取高程曲线数据", res);
        this.$nextTick(() => {
          this.getImg(res.data.data);
        });
        let detail = res.data.data;
        let x = []; // 横坐标
        let legendData = []; // 没数据的图例不显示
        let seriesData = []; // 数据

        detail.list.forEach((item) => {
          legendData.push(item.name);
          let arr = [];
          item.list.forEach((itm) => {
            if (x.indexOf(itm[0]) < 0) {
              x.push(itm[0]);
            }
            arr.push(itm[1]);
          });
          seriesData.push({
            name: item.name,
            type: "line",
            symbol: "circle",
            itemStyle: {
              normal: {
                lineStyle: {
                  type: item.name.indexOf("实测") >= 0 ? "solid" : "dashed"
                }
              }
            },
            data: arr
          });
        });

        this.$chart1.solidAndDashedLines("linearCharts", legendData, x, seriesData, "纵坐标(m)", "高程(m)");
      });
    },

    // 应力监控 获取图表数据
    getStressCharts() {
      console.log("点击应力监控");
      this.$axios.get(`${this.baseURL}buildpandect/stress/${this.projectId}`).then((res) => {
        console.log("获取应力监控高程曲线数据", res);
        this.$nextTick(() => {
          this.getImg(res.data.data);
        });
        let detail = res.data.data;
        let unit = detail.unit;
        let x = []; // 横坐标
        let y = []; // 纵坐标
        let ls = detail.alist; // 左实测高程
        let lm = detail.blist; // 左目标高程
        let ms = detail.clist; // 中实测高程
        let mm = detail.dlist; // 中目标高程
        let rs = detail.elist; // 右实测高程
        let rm = detail.flist; // 右目标高程
        let lsy = []; // 左实测y
        let lmy = []; // 左目标y
        let msy = []; // 中实测y
        let mmy = []; // 中目标y
        let rsy = []; // 右实测y
        let rmy = []; // 右目标y

        // let legendData = []; // 没数据的图例不显示
        let seriesData = []; // 数据

        detail.data.forEach((item) => {
          // legendData.push(item.name);
          console.log("循环值item", item, "mingcheng", item.name, item.name.indexOf("实测"), item.name.includes("实测"));
          seriesData.push({
            name: item.name,
            type: "line",
            symbol: "circle",
            itemStyle: {
              normal: {
                lineStyle: {
                  width: 2,
                  type: item.name.indexOf("实测") >= 0 ? "solid" : "dashed"
                }
              }
            },
            data: item.data
          });
        });
        // let x1 = x.sort(function (a, b) {
        //   return a - b;
        // });
        let x1 = detail.datax;
        console.log("左实测", lsy);
        console.log("左目标", lmy);
        console.log("中实测", msy);
        console.log("中目标", mmy);
        console.log("右实测", rsy);
        console.log("右目标", rmy);

        this.$chart1.solidAndDashedLines("stressCharts", [], x1, seriesData, "纵坐标(m)", "单位:" + unit);

        // let option = {
        //   tooltip: {
        //     trigger: "axis",
        //     show: true,
        //     formatter(d) {
        //       // console.log("ddddd", d);
        //       let html = "";
        //       for (let i = 0; i < d.length; i++) {
        //         if (i == 0) {
        //           html += `<div>纵坐标：${d[i].name}</div><p style="color:${d[i].color}">${d[i].marker} ${d[i].seriesName}：${d[i].value}</p>`;
        //         } else {
        //           html += `<p style="color:${d[i].color};text-align: left;">${d[i].marker} ${d[i].seriesName}：${d[i].value}</p>`;
        //         }
        //       }
        //       return html;
        //     }
        //   },
        //   color: ["#32C5FF", "#0068F7", "#CEA22B", "#5BD6AB", "#8464E0", "#A8E799", "#55A5FF", "#8BFFF1"],

        //   grid: {
        //     left: "3%",
        //     right: "3%",
        //     bottom: "8%",
        //     containLabel: true
        //   },

        //   xAxis: {
        //     name: "纵坐标(m)",
        //     type: "category",
        //     boundaryGap: false,
        //     axisLabel: {
        //       color: "#8A94A6",
        //       // interval: Math.ceil(time.length / 6),
        //       fontSize: 14,
        //       textStyle: {
        //         color: "#fff",
        //         fontSize: "10"
        //       },
        //       formatter: function (value) {
        //         return value.split(" ").join("\n");
        //       }
        //     },
        //     axisLine: {
        //       lineStyle: {
        //         color: "#1c2b51",
        //         type: "dashed"
        //       }
        //     },
        //     nameTextStyle: {
        //       align: "right",
        //       padding: [73, 0, 0, 3]
        //     },
        //     axisTick: {
        //       show: false
        //     },
        //     data: x1
        //   },
        //   yAxis: [
        //     {
        //       type: "value",
        //       name: unit,
        //       nameLocation: "center",
        //       scale: true,
        //       nameTextStyle: {
        //         color: "#58637f",
        //         fontSize: 14,
        //         padding: 45
        //       },
        //       axisLabel: {
        //         //  y轴左侧的文字
        //         color: "#58637f",
        //         fontSize: 14
        //       },
        //       axisLine: {
        //         //   y轴的颜色
        //         show: false,
        //         lineStyle: {
        //           color: "#9D9D9D"
        //         }
        //       },
        //       splitLine: {
        //         lineStyle: {
        //           color: "#1c2b51",
        //           type: "dashed"
        //         }
        //       },
        //       axisTick: {
        //         show: false
        //       }
        //     }
        //     // {
        //     //   type: "value",
        //     //   axisLine: {
        //     //     lineStyle: {
        //     //       color: "#9D9D9D"
        //     //     }
        //     //   },
        //     //   axisTick: {
        //     //     show: false
        //     //   }
        //     // }
        //   ],
        //   series: seriesData
        // };
        // document.getElementById("stressCharts").removeAttribute("_echarts_instance_");
        // let myChart = echarts.init(document.getElementById("stressCharts"));
        // myChart.setOption(option);
      });
    },

    // 类型 切换tab
    handleTypeClick(item) {
      this.typeActive = item.id;
      if (item.id == "1") {
        this.getLinearCharts(this.branchActive);
      } else {
        this.getStressCharts();
      }
    },
    // 线形监控 切换tab
    handleLinearClick(item) {
      this.branchActive = item.id;
      this.branchName = item.name;
      this.getLinearCharts(item.id);
    },
    // 关闭线形监测显示
    handleLineClose() {
      this.$emit("handleClosePage", "isShowLinear");
    }
  }
};
</script>

<style lang="scss" scoped>
.linear-wrapper {
  // width: 60%;
  width: 100%;
  left: 130px;
  position: absolute;
  bottom: 28px;
  // left: 20%;
  font-size: 12px;
  background: #04153f;
  padding: 10px 20px 10px 10px;
  border: 1px solid #2772f0;
  border-radius: 8px;
  .close.pointer {
    position: absolute;
    top: 14px;
    right: 20px;
  }
  .type-ul {
    // display: flex;
    // padding-bottom: 10px;
    // border-bottom: 1px solid #3e4f78;
    padding: 32px 14px 0px 4px;
    display: flex;
    flex-direction: column;
    li {
      // border: 1px solid #2667db;
      // border-radius: 17px;
      // padding: 6px 20px;
      // margin-right: 20px;
      margin-top: 8px;
      width: 32px;
      flex: 1;
      writing-mode: vertical-rl;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: linear-gradient(90deg, #104271 0%, #071d47 100%);
      border-radius: 8px;
      &:hover {
        cursor: pointer;
      }
    }
    li.active-color {
      // color: #fff;
      // background: #2667db;
      background-image: linear-gradient(90deg, rgba(50, 197, 255, 0.72) 0%, rgba(50, 197, 255, 0) 100%);
    }
  }
  .linear-content,
  .stress-content {
    // margin-top: 10px;
    .linear-ul {
      margin-top: 6px;
      height: 33px;
      // line-height: 38px;

      display: flex;
      .title {
        // text-align: center;
        // margin-top: 10px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 12px;
        color: #cbe1fc;
        letter-spacing: 0;
        &:hover {
          cursor: default;
        }
      }
      li {
        // border: 1px solid #2667db;
        // border-radius: 17px;
        // padding: 4px 18px;
        margin-right: 20px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 12px;
        color: #cbe1fc;
        letter-spacing: 0;
        position: relative;

        &:hover {
          cursor: pointer;
        }
      }
      li.active-color {
        color: #fff;
      }
      li.active-color::before {
        // color: #fff;
        // background: #2667db;
        content: "";
        position: absolute;
        width: 100%;
        height: 8px;
        opacity: 0.5;
        background-image: linear-gradient(90deg, rgba(50, 197, 255, 0.72) 0%, rgba(50, 197, 255, 0) 100%);
        border-radius: 15px;
        left: 24px;
        margin-left: -23px;
        top: 15px;
      }
    }

    .define-legend {
      position: absolute;
      right: 60px;
      top: 65px;
      .dotted-line {
        color: #a2bce7;
        font-weight: bold;
      }
      .my-divider {
        width: 25px;
        display: inline-block;
        margin: 0;
        background: #a2bce7;
        bottom: 2px;
      }
    }
    #linearCharts {
      width: 100%;
      height: 183px;
    }
    #stressCharts {
      width: 100%;
      height: 183px;
    }
  }
  .linear-content-bg {
    background-image: linear-gradient(180deg, rgba(32, 54, 112, 0.3) 0%, #0e1b4d 86%);
    border-radius: 24px;
    border-radius: 8px;
    padding: 17px 0px 15px;
  }
}
.line-w400 {
  height: 400px;
}
.line-w490 {
  height: 490px;
}
</style>
