<template>
  <div>
    <div class="title">
      <span>施工进度</span>
      <img src="@/assets/images/home/guanbi_button.png" alt="" @click="handleClosePress" />
    </div>
    <div class="content" style="padding-top: 15%; text-align: center" v-if="false">
      <img style="display: inline-block" src="@/assets/images/alone/ic_jiankong_zanwu.png" alt="" />
      <div>暂无进度</div>
    </div>
    <div class="construction-content-wrapper box">
      <div class="construction-left-chart">
        <div class="construction-chart" id="constructionTotal"></div>
        <div class="total-text">施工总进度</div>
      </div>
      <div class="construction-right-chart">
        <div class="construction-list">
          <div v-for="(item, udx) in activeList" :key="udx">
            <div class="construction-list-item" style="height: 22px">
              <div class="construction-list-item-bg"></div>
              <div class="cover-bg" :style="{ left: item.progress }"></div>
            </div>
            <div class="construction-char-name">{{ item.pierName }}</div>
          </div>
        </div>

        <div class="mar-top12">
          <div class="construction-text-dot">
            <span v-for="(itm, idx) in constructionCharts.list" :key="idx">
              <span v-if="idx % 2 == 1"> <span class="construction-dot pointer" :class="{ 'construction-dot-active': index == idx }" @click="selectChartIcon(idx)"></span> </span
            ></span>
            <span
              v-if="constructionCharts.list && constructionCharts.list.length > 2 && constructionCharts.list.length % 2 == 1"
              class="construction-dot pointer"
              :class="{ 'construction-dot-active': index == constructionCharts.list.length - 1 }"
              @click="selectChartIcon(constructionCharts.list.length - 1)"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    constructionData: {
      type: Object
    }
  },

  watch: {
    constructionData: {
      handler(n, o) {
        console.log("监听数据", n, o);
        // this.$nextTick(() => {
        //   this.constructionCharts = n;
        //   this.getGanttCharts();
        // });

        this.constructionCharts.totalProgress = n.totalProgress;
        this.constructionCharts.list.push(...n.list);
        this.activeList = JSON.parse(JSON.stringify(this.constructionData.list || [])).slice(0, 2);

        this.getGanttCharts();
      },
      immediate: true,
      deep: true
    }
  },
  created() {
    console.log("this.constructionCharts", this.constructionData);
    this.activeList = JSON.parse(JSON.stringify(this.constructionData.list || [])).slice(0, 2);
    // this.formateName();
  },
  mounted() {
    // this.getGanttCharts();
  },
  data() {
    return {
      index: 1,
      name1: "",
      name2: "",
      activeList: [],
      constructionCharts: { totalProgress: "", list: [] }
    };
  },
  methods: {
    handleClosePress() {
      this.$emit("handleClosePress");
    },
    formateName() {
      if (this.activeList.length >= 2) {
        this.name1 = this.activeList[0].pierName;
        this.name2 = this.activeList[1].pierName;
      } else if (this.activeList.length == 1) {
        this.name1 = this.activeList[0].pierName;
        this.name2 = "";
      } else {
        this.name1 = "";
        this.name2 = "";
      }
    },
    selectChartIcon(index) {
      if (this.constructionCharts.list.length && (this.constructionCharts.list.length - 1) % 2 == 0 && this.constructionCharts.list.length - 1 === index) {
        this.activeList = JSON.parse(JSON.stringify(this.constructionCharts.list || [])).slice(-1);
      } else {
        this.activeList = JSON.parse(JSON.stringify(this.constructionCharts.list || [])).slice(index - 1, index + 1);
      }
      console.log("点击点", index, this.activeList, this.constructionCharts.list.length);

      this.index = index;
      // this.formateName();
    },
    // 施工总进度图表
    getGanttCharts() {
      console.log("施工总进度数据", this.constructionCharts);
      // let color = ["#fbc292", "#06fbfe"];
      // let color = ["#7BFF", "#A1CBFF"];
      let color = ["#88DAFE", "#1A80EC"];

      let percentNum = this.constructionCharts.totalProgress;
      // let percentNum = 53.16 + '%'
      let num = 0;
      if (percentNum) {
        num = percentNum.split("%")[0];
      }
      // num = "40%";
      // percentNum = 60;
      // this.$nextTick(() => {
      //   this.$chart1.dashboardRing11("constructionTotal", color[0], color[1], percentNum, num);
      // });
      setTimeout(() => {
        document.getElementById("constructionTotal").removeAttribute("_echarts_instance_");

        this.$chart1.dashboardRing11("constructionTotal", color[0], color[1], percentNum, num);
      }, 50);
    }
  }
};
</script>

<style lang="scss" scoped>
.floatL {
  float: left;
}
.floatR {
  float: right;
}
.construction-content-wrapper {
  box-sizing: border-box;
  padding: 16px 14px 0 14px;

  .construction-left-chart {
    width: 163px;
  }
  .construction-right-chart {
    flex: 1;
  }
  .construction-chart {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .total-text {
    font-family: SourceHanSansCN-Medium;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
  }

  .construction-list {
    // margin-top: 24px;
    height: 108px;
    position: relative;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    .construction-char-name {
      text-align: center;
      margin-top: 3px;
      margin-bottom: 9px;
      font-family: SourceHanSansCN-Medium;
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: 0;
    }
    .construction-list-item {
      width: 140px;
      height: 100%;
      position: relative;
      padding: 3px 0px;
      box-sizing: border-box;
      border: 1px solid transparent;
      border-image: linear-gradient(#1a80ec, #88dafe) 10 10;
      .construction-list-item-bg {
        // background: linear-gradient(to right, #051430, #88dafe);
        background: url("../../../assets/img/zhw/chart-bg.png") no-repeat;
        background-size: cover;
        height: 100%;
      }
      .cover-bg {
        position: absolute;
        left: 60%;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(11, 45, 90, 1);
      }
    }
    .construction-text-name {
      width: 140px;
      height: 100%;
      position: relative;
    }
  }
  .mar-top12 {
    // margin-top: 12px;
    position: relative;

    height: 30px;
    .construction-text-dot {
      position: absolute;
      left: 50%;
      // top: 10px;
      margin-left: -50px;
      width: 100px;
      text-align: center;
      z-index: 9;
      height: 28px;
      .construction-dot {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #1550a0;
        margin-right: 8px;
      }
      .construction-dot-active {
        background: #2592c9;
      }
    }
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background: linear-gradient(90deg, #00398d, #051430 99%);
  border-radius: 8px 8px 0 0;
  img:hover {
    cursor: pointer;
  }
}
</style>
